




























import {Vue, Component} from 'vue-property-decorator';
import {ApplicationModule} from '../../store/modules/application';
import {UserModule} from '../../store/modules/user';

@Component
export default class ForgotPassword extends Vue {
    private email = '';

    private error = '';

    get loading(): boolean {
        return ApplicationModule.loading;
    }

    private forgotPassword(event: Event) {
        event.preventDefault();
        if (this.email && !this.loading) {
            UserModule.resetPassword(this.email);
            this.$router.replace((this.$route.query.redirect as string) || '/account/login');
        }
    }
}
